<template>
  <router-view />
</template>
<style lang="less">
html,body,#app {
  padding: 0px!important;
  margin: 0px!important;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  cursor: default;
  background: #FFFFFF;
  font-size: 12px;
  font-family: '微软雅黑', '微软雅黑 Regular', '微软雅黑', sans-serif, SimSun, serif;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard */
}
a{
  text-decoration: none;
}

.tox-tinymce-aux {
  z-index: 9999 !important;
}
.el-dialog__body{
  overflow: hidden;
}
*{
  box-sizing: border-box;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  text-align: center;
}
//修改所有删除提示的样式
.el-message-box{
  height: 200px;
  .el-message-box__header{
    border-bottom: 1px solid #ccc;
  }
  .el-message-box__content{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-message-box__btns{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button--primary{
      background: rgb(243,11,11);
      border: none;
    }
  }
}
.el-table__header-wrapper{
  --el-table-header-bg-color: #f0f0f0;
  --el-table-header-text-color: #000;
}
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  height: 100px;
  background-color: #eee;
}
.ancient{
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    //height: 100px;
    background-color: #5b753a;
  }
}
.el-loading-mask{
  background-color:transparent!important;
}
@font-face {
  font-family: 'iconfont';
  src: url('./../public/iconfont.woff2?t=1681712321974') format('woff2'),
  url('./../public/iconfont.woff?t=1681712321974') format('woff'),
  url('./../public/iconfont.ttf?t=1681712321974') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face{
  font-family:Alimama_ShuHeiTi_Bold; // 自定义名称，无需引号
  src:url('./../public/font/PINGFANG REGULAR.TTF') // 字体文件路径，支持本地文件，或字体文件链接地址
}
.ancient{
  body{
    background-color: red!important;
  }
}
</style>
